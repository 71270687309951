import { FieldValues } from "react-hook-form";

import MenuPageLayout from "@/components/layouts/MenuPageLayout";
import { request } from "@/utils/request";
import { useAppContext } from "@/contexts/app-context";
import { useResource } from "@/hooks/resources";
import { SettingsForm } from "@/components/forms/settings-form";

const SettingsPage = () => {
  const {
    state: { selectedChildId },
  } = useAppContext();

  const [userPreference, { isLoading }] = useResource<FieldValues>(
    `/user_preference?child_id=${selectedChildId}`,
    {},
  );

  const onSubmit = async (data: FieldValues) => {
    await request.put(`/user_preference?child_id=${selectedChildId}`, data);
  };

  return (
    <MenuPageLayout title="Settings">
      {!isLoading && userPreference && (
        <SettingsForm
          defaultValues={{
            playbackSpeed: userPreference.playbackSpeed.toFixed(1),
            showRewards: userPreference.showRewards.toString(),
            showVocabularyText: userPreference.showVocabularyText.toString(),
          }}
          onSubmit={onSubmit}
        />
      )}
    </MenuPageLayout>
  );
};

export default SettingsPage;
