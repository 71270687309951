import clsx from "clsx";
import {
  Children,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  ReactNode,
} from "react";
import { Control, FieldValues, useController } from "react-hook-form";

type RadioInputProps = HTMLAttributes<HTMLDivElement> & {
  name: string;
  label: ReactNode;
  hint?: string;
  control: Control<FieldValues>;
  required?: boolean;
  horizontal?: boolean;
};

export const RadioInput = ({
  name,
  label,
  hint,
  control,
  required = false,
  horizontal = false,
  className,
  children,
}: PropsWithChildren<RadioInputProps>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div
      className={clsx("w-full flex gap-x-4 gap-y-2", {
        "flex-col": !horizontal,
      })}
    >
      <div
        className={clsx({
          "w-1/3 flex items-center justify-end h-6": horizontal,
        })}
      >
        <label htmlFor={name} className={clsx({ "text-right": horizontal })}>
          {label}{" "}
          {required && (
            <span
              className="text-red-600 underline decoration-dotted cursor-help"
              title="required"
            >
              *
            </span>
          )}
        </label>
      </div>

      <div className="grow flex flex-col gap-y-2">
        <div
          className={clsx(className, {
            "flex flex-col gap-1": !className,
          })}
        >
          {Children.map(children, (child) => {
            if (!isValidElement(child)) return null;

            const { value, children, className } = child.props;

            return (
              <label className={clsx("flex gap-2 items-center", className)}>
                <input
                  {...field}
                  type="radio"
                  value={value}
                  checked={value == field.value}
                />
                {children}
              </label>
            );
          })}
        </div>

        {hint && <p className="text-xs text-gray-600">{hint}</p>}
        {error && <p className="text-sm text-red-600">{error.message}</p>}
      </div>
    </div>
  );
};
