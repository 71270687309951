import { FieldValues, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { RadioInput } from "@/components/inputs/radio-input";
import { customErrorMap } from "@/utils/custom-error-map";
import { Button } from "@/components/button";

type SettingsFormProps = {
  defaultValues: FieldValues;
  onSubmit: (data: FieldValues) => Promise<void>;
};

const formSchema = z.object({
  playbackSpeed: z.enum(["1.0", "0.8", "0.65"]),
  showRewards: z.enum(["true", "false"]),
  showVocabularyText: z.enum(["true", "false"]),
});

export const SettingsForm = ({
  defaultValues,
  onSubmit,
}: SettingsFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
  } = useForm<FieldValues>({
    defaultValues,
    resolver: zodResolver(formSchema, { errorMap: customErrorMap }),
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full max-w-screen-lg flex flex-col gap-8"
    >
      <div className="flex items-center justify-between border-b pb-4">
        <h2 className="text-4xl">Play Options</h2>
        <Button
          type="submit"
          className="btn-secondary"
          disabled={!isDirty}
          loading={isSubmitting}
        >
          SAVE
        </Button>
      </div>

      <RadioInput
        control={control}
        name="playbackSpeed"
        label={
          <div className="flex gap-2 items-baseline">
            <h3 className="text-lg">Playback Speed</h3>{" "}
            <span className="text-sm">(default: Normal)</span>
          </div>
        }
        className="flex flex-col lg:flex-row gap-4"
      >
        <option
          value="1.0"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          NORMAL
        </option>

        <option
          value="0.8"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          SLOWER
        </option>

        <option
          value="0.65"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          SLOWEST
        </option>
      </RadioInput>

      <RadioInput
        control={control}
        name="showRewards"
        label={
          <div>
            <div className="flex gap-2 items-baseline">
              <h3 className="text-lg">Rewards</h3>{" "}
              <span className="text-sm">(default: On)</span>
            </div>

            <p className="text-xs text-gray-600">
              Display animation between successful levels.
            </p>
          </div>
        }
        className="flex flex-col lg:flex-row gap-4"
      >
        <option
          value="true"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          ON
        </option>

        <option
          value="false"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          OFF
        </option>
      </RadioInput>

      <RadioInput
        control={control}
        name="showVocabularyText"
        label={
          <div>
            <div className="flex gap-2 items-baseline">
              <h3 className="text-lg">Show Vocabulary Words</h3>{" "}
              <span className="text-sm">(default: Show)</span>
            </div>

            <p className="text-xs text-gray-600">
              Show or Hide the Vocab words below images during game play.
            </p>
          </div>
        }
        className="flex flex-col lg:flex-row gap-4"
      >
        <option
          value="true"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          SHOW
        </option>

        <option
          value="false"
          className="rounded-2xl border-2 border-primary-blue-500 px-4 py-3 hover:bg-primary-blue-500 cursor-pointer has-[:checked]:bg-primary-blue-500 has-[:checked]:text-white"
        >
          HIDE
        </option>
      </RadioInput>
    </form>
  );
};
